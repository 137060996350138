import './App.css';
import React, { Component } from "react";
import ReactTooltip from 'react-tooltip';
import { Route, HashRouter, Switch } from 'react-router-dom';
import Web3 from 'web3';
import toastr from 'toastr';
import './toastr.css';
import Home from './components/home/Home';

import { MAINNET_HTTP_ETH, MAINNET_HTTP_BSC, isTestNetwork, LIST_ADDRESS_STAKE_ETH, LIST_ADDRESS_STAKE_BSC, LIST_ADDRESS_ERC20_ETH, LIST_ADDRESS_ERC20_BSC, LIST_ABI_STAKE, LIST_ABI_ERC20 } from './config'


export default class App extends Component {

  constructor() {
    super();
    this.state = {
      web3: null,
      networkType: null,
      chainId: null,
      account: null,
      isEnabled: false,
      isDefaultEnabled: false,
      isConnecting: false,
      LIST_ADDRESS_STAKE: null,
      LIST_ADDRESS_ERC20: null,
      stakeWeb3Inst: null,
      erc20Web3Inst: null,
      selectedToken: 'ETH',
      ethStakingInstance: null,
      bscStakingInstance: null,
      ethERC20Instance: null,
      bscERC20Instance: null,
      canShow: true,
    }

    toastr.options = {
      // positionClass: 'toast-top-full-width',
      hideDuration: 300,
      timeOut: 3000
    }

  }

  componentDidMount() {
  }

  async connectToMetaMaskNetwork() {
    if (this.state.isConnecting) {
      return;
    }
    this.setState({ isConnecting: true });
    if (window.ethereum) {
      await window.ethereum.enable().then((err, res) => {
      });
      this.state.web3 = new Web3(window.ethereum);
      window.ethereum.on('networkChanged', (chainId) => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      });
      window.ethereum.on('chainChanged', (chainId) => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      });
      window.ethereum.on('accountsChanged', (chainId) => {
        // handle the new network
        document.location.reload();
      });
      window.ethereum.on('disconnect', (chainId) => {
        document.location.reload();
      });
      try {
        await this.setConfig();
      } catch (e) {
        // User has denied account access to DApp...
        // console.log("ERROR:::::::::::::::::::::", e)
        await this.setState({ isEnabled: false });
      }
    } else if (window.web3 && window.web3.currentProvider) {
      // console.log("DONE::")
      window.web3.on('networkChanged', (chainId) => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      });
      window.web3.on('chainChanged', (chainId) => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      });
      window.web3.on('accountsChanged', (chainId) => {
        // handle the new network
        document.location.reload();
      });
      window.web3.on('disconnect', (chainId) => {
        document.location.reload();
      });
      this.state.web3 = new Web3(window.web3.currentProvider);
      try {
        await this.setConfig();
      } catch (e) {
        // User has denied account access to DApp...
        // console.log("ERROR:::::::::::::::::::::")
        await this.setState({ isEnabled: false, isConnecting: false });
        this.changesReflactor();
      }
    } else {
      await this.setState({ isEnabled: false, isConnecting: false });
      this.changesReflactor();
    }
  }

  async setConfig() {
    // console.log("Network changed")
    const Id = await this.state.web3.eth.net.getNetworkType();
    const chainId = await this.state.web3.eth.getChainId();
    const accounts = await this.state.web3.eth.getAccounts();
    this.state.web3.eth.defaultAccount = accounts[0];
    this.configNetwork(Id, chainId, accounts[0]);
  }

  async configNetwork(Id, chainId, account) {
    if ((isTestNetwork && chainId != 4 && chainId != 97) || (!isTestNetwork && chainId != 1 && chainId != 56)) {
      toastr.info('Wrong network choosen.');
      await this.setState({
        isEnabled: false,
        isConnecting: false,
        isDefaultEnabled: false,
        ethStakingInstance: null,
        bscStakingInstance: null,
        ethERC20Instance: null,
        bscERC20Instance: null
      });
      this.changesReflactor();
      return;
    } else if (chainId == 1 || chainId == 4 || chainId == 56 || chainId == 97) {
      let LIST_ADDRESS_STAKE = null;
      let LIST_ADDRESS_ERC20 = null;
      let selectedToken = null;
      if (chainId == 1 || chainId == 4) {
        LIST_ADDRESS_STAKE = LIST_ADDRESS_STAKE_ETH;
        LIST_ADDRESS_ERC20 = LIST_ADDRESS_ERC20_ETH;
        selectedToken = 'ETH';
      } else if (chainId == 56 || chainId == 97) {
        LIST_ADDRESS_STAKE = LIST_ADDRESS_STAKE_BSC;
        LIST_ADDRESS_ERC20 = LIST_ADDRESS_ERC20_BSC;
        selectedToken = 'BNB';
      }

      let stakeWeb3Inst = new this.state.web3.eth.Contract(LIST_ABI_STAKE, LIST_ADDRESS_STAKE);
      let erc20Web3Inst = new this.state.web3.eth.Contract(LIST_ABI_ERC20, LIST_ADDRESS_ERC20);

      const web3InfuraETH = new Web3(new Web3.providers.HttpProvider(MAINNET_HTTP_ETH));
      const web3InfuraBSC = new Web3(new Web3.providers.HttpProvider(MAINNET_HTTP_BSC));

      const ethStakingInstance = new web3InfuraETH.eth.Contract(LIST_ABI_STAKE, LIST_ADDRESS_STAKE_ETH);
      const bscStakingInstance = new web3InfuraBSC.eth.Contract(LIST_ABI_STAKE, LIST_ADDRESS_STAKE_BSC);

      const ethERC20Instance = new web3InfuraETH.eth.Contract(LIST_ABI_ERC20, LIST_ADDRESS_ERC20_ETH);
      const bscERC20Instance = new web3InfuraBSC.eth.Contract(LIST_ABI_ERC20, LIST_ADDRESS_ERC20_BSC);

      await this.setState({
        isEnabled: true,
        isConnecting: false,
        networkType: Id,
        chainId: chainId,
        account: account,
        selectedToken: selectedToken,
        LIST_ADDRESS_STAKE: LIST_ADDRESS_STAKE,
        LIST_ADDRESS_ERC20: LIST_ADDRESS_ERC20,
        stakeWeb3Inst: stakeWeb3Inst,
        erc20Web3Inst: erc20Web3Inst,

        isDefaultEnabled: true,
        ethStakingInstance: ethStakingInstance,
        bscStakingInstance: bscStakingInstance,
        ethERC20Instance: ethERC20Instance,
        bscERC20Instance: bscERC20Instance
      });
      this.changesReflactor();
    } else {
      this.changesReflactor();
    }
  }


  async changesReflactor() {
    await this.setState({ canShow: false });
    await this.setState({ canShow: true });
  }


  getShortAccountId() {
    let address = "" + (this.state.account ? this.state.account : '');
    return address.slice(0, 8) + "....." + address.slice(address.length - 3, address.length);
  }

  render() {
    return (
      <HashRouter hashType='slash'>
        {this.state.canShow ? (
          <Switch>
            <Route exact path='/' render={props => <Home prop={this.state} connect={() => { this.connectToMetaMaskNetwork() }}></Home>}></Route>
            <Route render={props => <Home prop={this.state} connect={() => { this.connectToMetaMaskNetwork() }}></Home>}></Route>
          </Switch>
        ) : ''}
      </HashRouter>
    );
  }
}
