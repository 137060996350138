import React, { Component } from "react";
import Web3 from 'web3';
import ReactTooltip from 'react-tooltip';
import { withGetScreen } from 'react-getscreen';
import $ from "jquery";
import toastr from 'toastr';
import '../../toastr.css';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mintAmount: 0,
            currentBal: '-',
            flashEarned: 0,
            flashEarnedUSD: 0,
            flashStaked: 0,
            flashStakedUSD: 0,
            flashBalance: 0,
            stakeAmount: 0,
            unStakeAmount: 0,
            mintStatus: 'start',
            selectedToken: this.props.prop.selectedToken,
            defaultTotalStakedETH: 0,
            defaultTotalStakedBSC: 0,
            defaultTotalStaked: 0,
            flashPriceInUsd: 0,
            apr: 0,
            totalSupply: 0,
            timesUp: false
        }

        toastr.options = {
            // positionClass: 'toast-top-full-width',
            hideDuration: 300,
            timeOut: 3000
        }

    }

    componentDidMount() {
        this.onCounterChange();
        if (this.props.prop.isEnabled) {
            this.loadData();
            this.loadDefaultData();
        }
        $("#dropdown").on("click", ".init", function () {
            $(this).closest("#dropdown").children('li:not(.init)').toggle();
        });

        var allOptions = $("#dropdown").children('li:not(.init)');
        $("#dropdown").on("click", "li:not(.init)", function () {
            allOptions.removeClass('selected');
            $(this).addClass('selected');
            $("#dropdown").children('.init').html($(this).html());
            allOptions.toggle();
        });
    }

    onCounterChange() {
        const daysElement = document.querySelector("#days");
        const hoursElement = document.querySelector("#hours");
        const minutesElement = document.querySelector("#minutes");
        const secondsElement = document.querySelector("#seconds");
        const monthsElement = document.querySelector("#months");

        // Get specific date and time in milliseconds from 1970 UTC
        let countDownDate = "1638219600000";

        setInterval(() => {
            // Get today's date and time in milliseconds from 1970 UTC
            let now = new Date().getTime();
            let distance = countDownDate - now;
            let months = Math.floor(distance / (1000 * 60 * 60 * 24 * 30));
            let days = Math.floor(
                (distance % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
            );
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (distance >= 0) {
                monthsElement.textContent = months;
                daysElement.textContent = days;
                hoursElement.textContent = hours;
                minutesElement.textContent = minutes;
                secondsElement.textContent = seconds;
            } else {
                this.setState({ timesUp: true })
            }
        }, 1000);
    }

    async loadDefaultData() {
        await this.callsDefaultABI();

        setTimeout(() => {
            if (this.props.prop.isEnabled) {
                this.loadData()
            }
        }, 30000)
    }

    callsDefaultABI() {
        try {
            this.loadDefaultData();
        } catch (e) {
            console.error(e)
        }
    }

    async loadDefaultData() {
        try {
            let defaultTotalStakedETH = await this.props.prop.ethStakingInstance.methods.balanceOf(this.props.prop.account).call();
            await this.setState({ defaultTotalStakedETH: this.getFormatedNumber(this.getFormatFrom(defaultTotalStakedETH), 1, 6) })
            let defaultTotalStakedBSC = await this.props.prop.bscStakingInstance.methods.balanceOf(this.props.prop.account).call();
            await this.setState({ defaultTotalStakedBSC: this.getFormatedNumber(this.getFormatFrom(defaultTotalStakedBSC), 1, 6) })
            this.setState({ defaultTotalStaked: this.state.defaultTotalStakedETH + this.state.defaultTotalStakedBSC })
        } catch (e) {
            console.error("===>", e)
        }
    }

    getLevel(isLength) {
        if (this.state.defaultTotalStaked) {
            let total = [];
            if (this.state.defaultTotalStaked >= 100000) {
                total = [1, 2, 3];
            } else if (this.state.defaultTotalStaked >= 30000) {
                total = [1, 2];
            } else if (this.state.defaultTotalStaked >= 10000) {
                total = [1];
            }
            return isLength ? total.length : total;
        }
        return isLength ? 0 : []
    }

    getIcons() {
        if (this.state.defaultTotalStaked) {
            let total = this.getLevel(false);
            if (total.length <= 0) {
                return;
            }
            return total.map((e, key) => {
                return <i className="fa fa-bolt"></i>;
            })
        }
    }

    async loadData() {

        await this.callsABI();

        setTimeout(() => {
            if (this.props.prop.isEnabled) {
                this.loadData()
            }
        }, 5000)
    }

    async callsABI() {
        try {
            await this.getFlashPriceInUsd();
            this.loadUserEthBalance();
            this.loadFlashEarned();
            this.loadFlashStaked();
            this.loadFlashBalance();
            this.loadAPR();
            this.totalSupply();
        } catch (e) {
            console.error(e)
        }
    }

    async loadUserEthBalance() {
        try {
            let currentBal = await this.props.prop.web3.eth.getBalance(this.props.prop.account);
            this.setState({ currentBal: this.getFormatedNumber(this.getFormatFrom(currentBal), 1, 6) })
        } catch (e) {
            console.error(e)
        }
    }

    async loadFlashEarned() {
        try {
            let earnedBal = await this.props.prop.stakeWeb3Inst.methods.earned(this.props.prop.account).call();
            let num = Number(this.getFormatedNumber(this.getFormatFrom(earnedBal), 1, 6));
            let earnedUSD = this.getFormatedNumber((this.state.flashPriceInUsd * num), 1, 2);
            this.setState({ flashEarned: num, flashEarnedUSD: earnedUSD })
        } catch (e) {
            console.error("===>1", e)
        }
    }

    async loadFlashStaked() {
        try {
            let stakedBal = await this.props.prop.stakeWeb3Inst.methods.balanceOf(this.props.prop.account).call();
            let num = Number(this.getFormatedNumber(this.getFormatFrom(stakedBal), 1, 6))
            let stakedUSD = this.getFormatedNumber((this.state.flashPriceInUsd * num), 1, 2);
            this.setState({ flashStaked: num, flashStakedUSD: stakedUSD });
        } catch (e) {
            console.error("===>2", e)
        }
    }

    async loadFlashBalance() {
        try {
            let flashBal = await this.props.prop.erc20Web3Inst.methods.balanceOf(this.props.prop.account).call();
            this.setState({ flashBalance: this.getFormatedNumber(this.getFormatFrom(flashBal), 1, 6) })
        } catch (e) {
            console.error("===>3", e)
        }
    }

    async getFlashPriceInUsd() {
        try {
            let flashPriceInUsd = await this.props.prop.stakeWeb3Inst.methods.getFlashPriceInUsd().call();
            this.setState({ flashPriceInUsd: Number(this.getFormatedNumber(Number(flashPriceInUsd), 1e18, 6)) })
        } catch (e) {
            console.error("===>4", e)
        }
    }

    async loadAPR() {
        try {
            let aprValue = await this.props.prop.stakeWeb3Inst.methods.getAPY().call();
            this.setState({ apr: Number(this.getFormatedNumber(Number(aprValue), 1000000, 6)) })
        } catch (e) {
            console.error("===>5", e)
        }
    }

    async totalSupply() {
        try {
            let totalSupply = await this.props.prop.stakeWeb3Inst.methods.totalSupply().call();
            this.setState({ totalSupply: Number(this.getFormatedNumber(this.getFormatFrom(totalSupply), 1, 6)) })
        } catch (e) {
            console.error("===>5", e)
        }
    }

    async changeToken(type) {
        let selectedType = null;
        if (this.props.prop.chainId == 1 || this.props.prop.chainId == 4) {
            selectedType = 'ETH';
        } else if (this.props.prop.chainId == 56 || this.props.prop.chainId == 97) {
            selectedType = 'BNB';
        }

        if (this.props.prop.isEnabled && selectedType && type != selectedType) {
            toastr.error("Wrong network connected. The app is currently on " + (selectedType ? selectedType : 'Private') + " mode but your wallet is connected to another network. Please change the network in your wallet settings and try again.")
            return;
        }

        await this.setState({ selectedToken: type });
        this.props.prop.selectedToken = type;
    }

    stakeAmountChanged(event) {
        if (this.state.mintStatus == "inprogress") {
            return;
        }
        let number = Number(event.target.value);
        if (event.target.value == '.') {
            event.target.value = '0.';
            number = 0;
        }
        // console.log(num)
        if (!isNaN(number) && number < 10000000000) {
            let dec = (event.target.value + '').split('.');
            if (dec[1] && dec[1].length > 6) {
                return;
            }
            if (dec.length == 1) {
                this.setState({ stakeAmount: number });
            } else {
                this.setState({ stakeAmount: event.target.value + '' });
            }
        }
    }

    unstakeAmountChanged(event) {
        if (this.state.mintStatus == "inprogress") {
            return;
        }
        let number = Number(event.target.value);
        if (event.target.value == '.') {
            event.target.value = '0.';
            number = 0;
        }
        // console.log(number)
        if (!isNaN(number) && number < 10000000000) {
            let dec = (event.target.value + '').split('.');
            if (dec[1] && dec[1].length > 6) {
                return;
            }
            if (dec.length == 1) {
                this.setState({ unStakeAmount: number });
            } else {
                this.setState({ unStakeAmount: event.target.value + '' });
            }

        }
    }

    async stakeClick() {
        if (this.props.prop.isEnabled) {
            if (!this.props.prop.account) {
                toastr.error("Plase connect metamask.");
                return;
            }

            // if (!this.state.timesUp) {
            //     toastr.error("Staking not live yet");
            //     return;
            // }

            if (this.isAnyTransactionInProgress()) {
                toastr.warning("One transaction already in progress.")
                return;
            }

            if (!this.state.stakeAmount || Number(this.state.stakeAmount) <= 0) {
                toastr.error("Plase enter valid amount.");
                return;
            }

            if (Number(this.state.stakeAmount) > Number(this.state.flashBalance)) {
                toastr.error("Your available balance is " + this.state.flashBalance + ". Please enter amount accordingly");
                return;
            }

            this.setState({ mintStatus: 'inprogress' });
            this.allownce();
        } else {
            toastr.error("Please connect wallet.");
        }
    }

    async allownce() {
        this.setState({ mintStatus: 'inprogress' });
        let allowance = await this.props.prop.erc20Web3Inst.methods.allowance(this.props.prop.account, this.props.prop.LIST_ADDRESS_STAKE).call();
        if (Number(allowance) >= Number(this.state.stakeAmount)) {
            this.stackCall();
        } else {
            this.approve();
        }
    }

    async approve() {
        if (this.props.prop.isEnabled) {
            let approveAmount = this.props.prop.web3.utils.toWei("115792089237316195423570985008687907853269984665640564039457584007", "mwei");
            let account = this.props.prop.account;
            this.setState({ mintStatus: 'inprogress' });
            await this.props.prop.erc20Web3Inst.methods.approve(this.props.prop.LIST_ADDRESS_STAKE, approveAmount.toString()).send({
                from: account
            }).then(async (result) => {
                this.stackCall();
            }).catch(error => {
                // console.log(error);
                this.setState({ mintStatus: 'start' });
                if (error.code === 4001) {
                    toastr.error('Transaction Rejected!')
                } else {
                    toastr.error('Transaction Failed!')
                }
            });
        }
    }

    async stackCall() {
        this.setState({ mintStatus: 'inprogress' });
        let stakeAmount = this.props.prop.web3.utils.toWei((this.state.stakeAmount).toString(), "ether");
        let account = this.props.prop.account;
        await this.props.prop.stakeWeb3Inst.methods.stake(stakeAmount).send({
            from: account
        }).then((result) => {
            // console.log("%o", result.transactionHash);
            this.setState({ mintStatus: 'start', stakeAmount: '' })
            this.callsABI();
            this.callsDefaultABI();
            toastr.success("Staking successful");
        }).catch(error => {
            // console.log(error);
            this.setState({ mintStatus: 'start' });
            if (error.code === 4001) {
                toastr.error('Transaction Rejected!')
            } else {
                toastr.error('Transaction Failed!')
            }
        });
    }

    async unstakeClick() {
        if (this.props.prop.isEnabled) {
            if (!this.props.prop.account) {
                toastr.error("Plase connect metamask.");
                return;
            }

            // if (!this.state.timesUp) {
            //     toastr.error("Staking not live yet");
            //     return;
            // }

            if (this.isAnyTransactionInProgress()) {
                toastr.warning("One transaction already in progress.")
                return;
            }

            if (!this.state.flashStaked || Number(this.state.flashStaked) <= 0) {
                toastr.error("You do not have Flash Staked balance");
                return;
            }

            if (!this.state.unStakeAmount || Number(this.state.unStakeAmount) <= 0) {
                toastr.error("Please enter valid amount for unstake");
                return;
            }

            if (Number(this.state.unStakeAmount) > Number(this.state.flashStaked)) {
                toastr.error("Your available balance is " + this.state.flashStaked + ". Please enter amount accordingly");
                return;
            }

            this.setState({ mintStatus: 'inprogress' });
            let unStakeAmount = this.props.prop.web3.utils.toWei((this.state.unStakeAmount).toString(), "ether");
            let account = this.props.prop.account;
            await this.props.prop.stakeWeb3Inst.methods.unstake(unStakeAmount).send({
                from: account
            }).then((result) => {
                // console.log("%o", result.transactionHash);
                this.setState({ mintStatus: 'start' })
                this.callsABI();
                this.callsDefaultABI();
                toastr.success("Unstake Flash token successfully done.");
            }).catch(error => {
                // console.log(error);
                this.setState({ mintStatus: 'start' });
                if (error.code === 4001) {
                    toastr.error('Transaction Rejected!')
                } else {
                    toastr.error('Transaction Failed!')
                }
            });
        } else {
            toastr.error("Please connect wallet.");
        }
    }

    async collect() {
        if (this.props.prop.isEnabled) {
            if (!this.props.prop.account) {
                toastr.error("Plase connect metamask.");
                return;
            }

            // if (!this.state.timesUp) {
            //     toastr.error("Staking not live yet");
            //     return;
            // }

            if (this.isAnyTransactionInProgress()) {
                toastr.warning("One transaction already in progress.")
                return;
            }

            if (!this.state.flashEarned || Number(this.state.flashEarned) <= 0) {
                toastr.error("You do not have Flash Earned balance");
                return;
            }

            this.setState({ mintStatus: 'inprogress' });
            let flashEarned = this.props.prop.web3.utils.toWei((this.state.flashEarned).toString(), "ether");
            let account = this.props.prop.account;
            await this.props.prop.stakeWeb3Inst.methods.getReward().send({
                from: account
            }).then((result) => {
                // console.log("%o", result.transactionHash);
                this.setState({ mintStatus: 'start' })
                this.callsABI();
                this.callsDefaultABI();
                toastr.success("Collect Flash token successfully done.");
            }).catch(error => {
                // console.log(error);
                this.setState({ mintStatus: 'start' });
                if (error.code === 4001) {
                    toastr.error('Transaction Rejected!')
                } else {
                    toastr.error('Transaction Failed!')
                }
            });
        } else {
            toastr.error("Please connect wallet.");
        }
    }

    getFormatTo(amount) {
        return this.props.prop.web3.utils.toWei((amount).toString(), "ether");
    }

    getFormatFrom(amount) {
        return this.props.prop.web3.utils.fromWei((amount).toString(), "ether");
    }

    getFormatedNumber(num, divideBy, fixUpto) {
        if (num == 0) {
            return 0
        }
        return Number(this.getDecimals((num / divideBy).toFixed(fixUpto)));
    }

    getDecimals(str) {
        if (str.endsWith('.000000')) {
            return Number(str.replace('.000000', ''));
        } else if (str.endsWith('.00000')) {
            return Number(str.replace('.00000', ''));
        } else if (str.endsWith('.0000')) {
            return Number(str.replace('.0000', ''));
        } else if (str.endsWith('.000')) {
            return Number(str.replace('.000', ''));
        } else if (str.endsWith('.00')) {
            return Number(str.replace('.00', ''));
        } else if (str.endsWith('.0')) {
            return Number(str.replace('.0', ''));
        } else {
            return Number(str)
        }
    }

    getShortAccountId() {
        let address = "" + (this.props.prop.account ? this.props.prop.account : '');
        if (!address) {
            return '-';
        }
        return address.slice(0, 8) + "....." + address.slice(address.length - 5, address.length);
    }

    componentWillUnmount() {
    }

    isAnyTransactionInProgress() {
        if (this.state.mintStatus == 'inprogress') {
            return true;
        }
        return false;
    }

    getLink(type, value) {
        if (this.props.prop.chainId == 1) {
            return "https://etherscan.io/" + type + "/" + value;
        } else if (this.props.prop.chainId == 4) {
            return "https://rinkeby.etherscan.io/" + type + "/" + value;
        } else if (this.props.prop.chainId == 56) {
            return "https://bscscan.com/" + type + "/" + value;
        } else if (this.props.prop.chainId == 97) {
            return "https://testnet.bscscan.com/" + type + "/" + value;
        } else {
            return ''
        }
    }

    render() {
        return (
            <>
                <section className="header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="logo">
                                    <img src="images/logo.png" />
                                </div>
                                <ul className="menu_strip">
                                    <li className="active">FLASH staking</li>
                                    <li>Fee revenue dashboard (coming soon)</li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <div className="pull-right">
                                    <div className="coin_header" style={{ fontSize: '16px' }}>
                                        <a className="but-analog-input form-control-2 btn-outline dropdown-toggle" href="#" role="button" id="dropdownBNB"
                                            data-bs-toggle="dropdown" aria-expanded="true" style={{ color: '#fff', textDecoration: 'none' }}>
                                            <img src={"/images/" + (this.state.selectedToken == 'ETH' ? 'image-eth.png' : 'bnb.png')} /> {this.state.selectedToken == 'ETH' ? 'Ethereum' : 'Binance'}</a>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownBNB" data-popper-placement="bottom-start">
                                            <li onClick={(e) => this.changeToken('ETH')} style={{ cursor: 'pointer' }}><a className="dropdown-item menu-item" style={{ color: '#fff' }}><img src='images/image-eth.png' />&nbsp;&nbsp;Ethereum</a></li>
                                            <li onClick={(e) => this.changeToken('BNB')} style={{ cursor: 'pointer' }}><a className="dropdown-item menu-item" style={{ color: '#fff' }}><img src='images/bnb.png' />&nbsp;&nbsp;Binance</a></li>
                                        </ul>
                                    </div>
                                    {this.props.prop.isEnabled ? <ul className="wallet_menu">
                                        <li>{this.state.currentBal} {this.state.selectedToken}</li>
                                        <li className="active">{this.getShortAccountId()}</li>
                                    </ul> :
                                        <ul className="wallet_menu">
                                            <li className="active" style={{ cursor: 'pointer' }} onClick={() => { this.props.connect() }}>Connect</li>
                                        </ul>}
                                    {/* <div className="ellipsis_menu">
                                        <i className="fa fa-ellipsis-h fa-2x" aria-hidden="true"></i>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* {!this.state.timesUp ?
                    <div className="counter-div">
                        <main className="container-counter">
                            <div className="counter-wrapper">
                                <div className="counter">
                                    <h1 className="header">We're launching soon</h1>
                                    <div className="count">
                                        <div className="months sec">
                                            <h2 id="months">00</h2>
                                            <p>Months</p>
                                        </div>
                                        <div className="days sec">
                                            <h2 id="days">00</h2>
                                            <p>Days</p>
                                        </div>
                                        <div className="hours sec">
                                            <h2 id="hours">00</h2>
                                            <p>Hours</p>
                                        </div>
                                        <div className="minutes sec">
                                            <h2 id="minutes">00</h2>
                                            <p>Minutes</p>
                                        </div>
                                        <div className="seconds sec">
                                            <h2 id="seconds">00</h2>
                                            <p>Seconds</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                    : ""} */}

                <section className="flash">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mt-4">
                                <div className="flash_contain">
                                    <div className="flash_header">
                                        <div className="flash_header_title">
                                            <div style={{ fontSize: '21px', fontWeight: '500' }}>FLASH pool</div>
                                            <div style={{ fontSize: '16px' }}>Stake FLASH, earn FLASH</div>
                                            <div style={{ fontSize: '14px', background: '#000', width: '120px' }}>{this.state.selectedToken == 'ETH' ? 'Ethereum' : 'Binance'} mainnet</div>
                                        </div>
                                        <div className="flash_header_logo">
                                            <img src="images/logo.png" />
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="flash_main_contain">
                                        <div className="apr">
                                            <div className="pull-left" style={{ fontWeight: '600' }}>Flash Balance:</div>
                                            <div className="pull-right" style={{ fontWeight: '600' }}>{this.state.flashBalance}</div>
                                        </div>
                                        <div className="clear"></div>
                                        <div className="apr">
                                            <div className="pull-left" style={{ textDecoration: 'underline' }}>APR:</div>
                                            <div className="pull-right">{this.state.apr}%&nbsp;&nbsp;</div>
                                            {/* <div className="pull-right">{this.state.apr}%&nbsp;&nbsp;<i className="fa fa-calculator" aria-hidden="true"></i></div> */}
                                        </div>
                                        <div className="clear"></div>
                                        <div className="flush_earned">
                                            <div className="pull-left">
                                                <div><span style={{ color: '#00efa9' }}>FLASH</span> earned</div>
                                                <div style={{ fontSize: '21px' }}>{this.state.flashEarned}</div>
                                                <div>{this.state.flashEarnedUSD} USD</div>
                                            </div>
                                            <div className="pull-right mt-4">
                                                <div style={{ backgroundImage: 'linear-gradient(to right, #019368 , #00eca7)', cursor: 'pointer', padding: '2px 30px', borderRadius: '4px' }} onClick={() => { this.collect() }}>Collect</div>
                                            </div>
                                        </div>

                                        <div className="clear"></div>
                                        <div className="flush_staked">
                                            <div className="pull-left">
                                                <div><span style={{ color: '#00efa9' }}>FLASH</span> staked</div>
                                                <div style={{ fontSize: '21px' }}>{this.state.flashStaked}</div>
                                                <div>{this.state.flashStakedUSD} USD</div>
                                            </div>
                                            <div className="pull-right mt-3" style={{ display: 'flex' }}>
                                                <input type="text" placeholder="Stake Amount" disabled={this.state.mintStatus == 'inprogress'} value={this.state.stakeAmount} onChange={(event) => this.stakeAmountChanged(event)} />
                                                <div style={{ backgroundImage: 'linear-gradient(to right, #019368 , #00eca7)', cursor: 'pointer', padding: '2px 30px', borderRadius: '4px' }} onClick={() => { this.stakeClick() }}>Stake</div>
                                            </div>
                                        </div>

                                        <div className="clear"></div>
                                        <div className="flush_staked">
                                            <div className="pull-left">
                                                <div>Unstake <span style={{ color: '#00efa9' }}>FLASH</span></div>
                                            </div>
                                            <div className="pull-right mt-3" style={{ display: 'flex' }}>
                                                <input type="text" placeholder="unstake Amount" disabled={this.state.mintStatus == 'inprogress'} value={this.state.unStakeAmount} onChange={(event) => this.unstakeAmountChanged(event)} />
                                                <div style={{ backgroundImage: 'linear-gradient(to right, #019368 , #00eca7)', cursor: 'pointer', padding: '2px 30px', borderRadius: '4px' }} onClick={() => { this.unstakeClick() }}>Unstake</div>
                                            </div>
                                        </div>

                                        <div className="clear"></div>
                                        <div className="total_staked">
                                            <div className="pull-left">
                                                Total <span style={{ color: '#00efa9' }}>FLASH</span> staked:
                                            </div>
                                            <div className="pull-right text-right">
                                                <div>{this.state.totalSupply} FLASH</div>
                                                <div style={{ color: '#00efa9', cursor: 'pointer', textDecoration: 'underline', }}><a href={this.getLink('token', this.props.prop.LIST_ADDRESS_ERC20)} target="_blank">see token info</a></div>
                                                <div style={{ color: '#00efa9', cursor: 'pointer', textDecoration: 'underline' }}><a href={this.getLink('address', this.props.prop.LIST_ADDRESS_STAKE)} target="_blank">View contract</a></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-4">
                                <div className="membership_overview_main">
                                    <div className="membership_overview_heading">
                                        Your membership overview
                                    </div>
                                </div>
                                <div className="membership_overview_contain">
                                    <div className="flush_token">
                                        <div className="pull-left">
                                            <div style={{ fontSize: '18.5px' }}>FLASH token staked</div>
                                            <div>Total FLASH staked across all networks.</div>
                                            <div style={{ margin: '0 0 10px 16px' }}>On Ethereum network</div>
                                            <div style={{ marginLeft: '16px' }}>On Binance Smart Chain network</div>
                                        </div>
                                        <div className="pull-right">
                                            <div style={{ color: '#3f56f0', fontSize: '18.5px', marginBottom: '15px' }}>{this.state.defaultTotalStaked} FLASH</div>
                                            <div className="pull-right">
                                                <div style={{ marginBottom: '8px' }}>{this.state.defaultTotalStakedETH} FLASH</div>
                                                <div>{this.state.defaultTotalStakedBSC} FLASH</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                    <div className="unlocked_level">
                                        <div className="pull-left">
                                            <div style={{ fontSize: '18.5px' }}>Unlocked level</div>
                                            <div>Membership level based on stake amount</div>
                                        </div>
                                        <div className="pull-right">
                                            <div>{this.getIcons()}<span style={{ color: '#3f56f0', fontSize: '18.5px' }}>LEVEL {this.getLevel(true)}</span></div>
                                        </div>
                                    </div>

                                    <div className="clear"></div>
                                    {/* <div className="feature">
                                        <div className="pull-left">
                                            <div style={{ fontSize: '18.5px' }}>Features/Benefits</div>
                                            <div>Unlocked membership benefits highlighted</div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                    <ul>
                                        <li>Access to Flashloan tool</li>
                                        <li>Access to "Arbitrage analytics'</li>
                                        <li>Profile saver - Create & save a complex flashloan for future use</li>
                                        <li>Full analytics suite including 'Insight tool and all future</li>
                                        <li>analytics products added to the platform</li>
                                        <li>Zero platform trading fees</li>
                                        <li>Ability to schedule trades with automation tool - Flashbots.com</li>
                                    </ul> */}
                                    {/* <div className="text-center">More info on FLASH token staking program below</div> */}
                                </div>
                            </div>

                            <div className="col-lg-6" style={{ color: '#fff', fontSize: '16px' }}>
                                <div>Staking FLASH tokens has four various membership benefits:</div>
                                <ul>
                                    <li>Platform Features</li>
                                    <li>FLASH token mining bonus</li>
                                    <li>Revenue share</li>
                                    <li>Fee Reduction</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="level_main">
                    <div className="container">
                        <div className="row" style={{ borderLeft: '4px #00efa9 solid' }}>
                            <div className="level_heading">
                                <i className="fa fa-bar-chart"></i><span style={{ marginLeft: '8px' }}>Membership levels</span>
                            </div>
                            <div className="col-lg-12">
                                <div className="row ml-3">
                                    <div className="col-lg-2">Stake</div>
                                    <div className="col-lg-2 text-center">Level</div>
                                    <div className="col-lg-6">Features</div>
                                    <div className="col-lg-2 text-center">Fee reduction</div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="row mt-3 ml-2" style={{ background: '#000a07', padding: '18px 12px', borderRadius: '12px', fontSize: '21px' }}>
                                    <div className="col-lg-2 mt-3">None</div>
                                    <div className="col-lg-2 text-center mt-3">0</div>
                                    <div className="col-lg-6 mt-4">
                                        <ul style={{ fontSize: '14px', height: '28px' }}>
                                            <li>Full access to the Flashloan tool</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-2 text-center mt-3">0%</div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="row mt-3 ml-2" style={{ background: '#001b13', padding: '18px 12px', borderRadius: '12px', fontSize: '19.5px' }}>
                                    <div className="col-lg-2 mt-3">10,000 FLASH</div>
                                    <div className="col-lg-2 text-center mt-3">1</div>
                                    <div className="col-lg-6">
                                        <ul style={{ fontSize: '14px' }}>
                                            <li>Level O features</li>
                                            <li>Access to 'Arbitrage analytics</li>
                                            <li>Profile saver - Create & save a complex flashloan for future use</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-2 text-center mt-3">-30%</div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row mt-3 ml-2" style={{ background: '#00523a', padding: '18px 12px', borderRadius: '12px', fontSize: '19.5px' }}>
                                    <div className="col-lg-2 mt-3">30,000 FLASH</div>
                                    <div className="col-lg-2 text-center mt-3">2</div>
                                    <div className="col-lg-6">
                                        <ul style={{ fontSize: '14px' }}>
                                            <li>Level 1 features</li>
                                            <li>Full analytics suite including 'Insight tool and all future<br /> analytics products added to the platform
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-2 text-center mt-3">-50%</div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row mt-3 ml-2" style={{ background: '#009d6f', padding: '18px 12px', borderRadius: '12px', fontSize: '19.5px' }}>
                                    <div className="col-lg-2 mt-3">100,000 FLASH</div>
                                    <div className="col-lg-2 text-center mt-3">3</div>
                                    <div className="col-lg-6">
                                        <ul style={{ fontSize: '14px' }}>
                                            <li>Level 2 features</li>
                                            <li>Zero platform trading fees.</li>
                                            <li>Ability to schedule trades with automation tool - <b>Flashbots.com</b></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-2 text-center mt-3">-100%<span style={{ fontSize: '14px' }}>(no fees)</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="token_mining_main mt-5">
                    <div className="container pb-4">
                        <div className="row" style={{ fontSize: '16px' }}>
                            <div className="col-lg-6" style={{ borderLeft: '4px #00efa9 solid' }}>
                                <div style={{ color: '#00efa9', fontSize: '18.5px' }} className="mt-3"><i className="fa fa-bolt" aria-hidden="true"></i>&nbsp;FLASH token mining</div>
                                <div className="mt-3">For the 4 vears of the platform being live users will benefit from FLASH token staking - there is a maximum of 8 million tokens available to be mined and this will be distributed through our adoption ladder programme which rewards early stakers of the platform and reduces the available supply every year for 4 years.</div>
                                <div className="mt-3"><span style={{ fontWeight: 'bold', fontSize: '16px' }}>Token Mining</span> - Period - 4 years</div>
                                <div className="mt-3">Available tokens - 8,000,000</div>
                                <div className="mt-3">Year 1-4,000,000<br /> Year 2-2,000,000<br /> Year 3 - 1,000,000<br /> Year 4 -1,000,000</div>
                                <div className="mt-3">Once this period is completed users will be able to receive a percentage of platform fees in place of the token mining revenues</div>
                            </div>

                            <div className="col-lg-6" style={{ borderLeft: '4px #00efa9 solid' }}>
                                <div style={{ color: '#00efa9', fontSize: '18.5px' }} className="mt-3"><i className="fa fa-money" aria-hidden="true"></i>&nbsp;Revenue share</div>
                                <div className="mt-3">50% of the fees generated on the flashloans.com platform will be distributed to all users staking FLASH.</div>
                                <div className="mt-3" style={{ fontWeight: 'bold', fontSize: '16px' }}>Revenue share</div>
                                <div className="mt-3">By staking FLASH tokens - users will also be able to receive 50% of the fees generated on the flashloans.com platform.</div>
                                <div className="mt-3">All fees will show on your staking dashboard which can be removed along with FLASH token rewards through staking in the first 4 years</div>
                            </div>
                        </div>
                    </div>
                </section>

                {(this.isAnyTransactionInProgress()) ? (<div id="toast-container" className="toast-top-right">
                    <div className="toast toast-info" aria-live="assertive">
                        <div className="toast-message">Transaction is in progress.</div>
                    </div>
                </div>) : ''}
                <ReactTooltip />
            </>
        );
    }
}

export default withGetScreen(Home);